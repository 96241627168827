import { FC } from 'react';

import { ArrowsRightIcon } from 'components/Icons';
import { Hourglass01Icon } from 'components/v2/Icons';

import TransactionType, {
  getBadgeIconByTransactionType,
  getIconByTransactionType,
} from 'enums/TransactionType';
import TransactionStatus, {
  getAvatarIconByTransactionStatus,
  getLabelIdByTransactionStatus,
  getTransactionDetailIconByTransactionStatus,
  getTransactionDetailIconColorByTransactionStatus,
} from 'enums/TransactionStatus';
import { getIconByMcc } from 'enums/CardMerchantCategoryCode';
import { TAvatarProps } from 'components/v2/core/Avatar/Avatar.types';
import getAvatarLetters from 'helpers/avatarLetters';
import { getAvatarColorKeyByHash } from 'components/v2/BeneficiaryItem/BeneficiaryItem.helpers';
import { TIconProps } from 'components/v2/Icons/Icon.types';
import { TStatusProps } from 'components/v2/core/Status/Status.types';
import FxOrderStatus from 'enums/FxOrderStatus';

import { TGetTransactionAvatarParams, TGetTransactionStatusPropsParams } from './transaction.types';

export function getTransactionAvatarProps({
  title,
  transactionStatus,
  transactionType,
  cardMerchantCategoryCode,
  merchantLogoLink,
  isBulkTransfer,
}: TGetTransactionAvatarParams): TAvatarProps {
  const AvatarIcon = getAvatarIcon();
  const BadgeIcon = getBadgeIcon();
  const avatarColor = getAvatarColorKeyByHash(title);
  const isRevert = [TransactionStatus.REVERSED, TransactionStatus.DECLINED].includes(
    transactionStatus
  );

  function getBadgeIcon() {
    if (isBulkTransfer) {
      return ArrowsRightIcon as FC<TIconProps>;
    }
    return getBadgeIconByTransactionType(transactionType);
  }

  function getAvatarIcon() {
    const iconByTransactionStatus = getAvatarIconByTransactionStatus(transactionStatus);

    if (cardMerchantCategoryCode) {
      return getIconByMcc(cardMerchantCategoryCode);
    }

    if (iconByTransactionStatus) {
      return iconByTransactionStatus;
    }

    return getIconByTransactionType(transactionType);
  }

  return {
    label: getAvatarLetters(title),
    icon: AvatarIcon,
    themeIconColor: 'textSecondary',
    imageUrl: merchantLogoLink,
    backgroundColor: AvatarIcon ? 'backgroundSecondary' : avatarColor,
    ...(BadgeIcon &&
      (!isRevert || isBulkTransfer) && {
        badgeProps: {
          icon: BadgeIcon,
          iconColor: 'textSecondary',
        },
      }),
  };
}

export function getTransactionStatusProps({
  fxOrder,
  status,
  type,
}: TGetTransactionStatusPropsParams): TStatusProps {
  if (
    fxOrder?.status === FxOrderStatus.PENDING ||
    (type === TransactionType.CARD_RESERVATION && status === TransactionStatus.APPROVED)
  ) {
    return {
      labelId: 'label.pending',
      icon: Hourglass01Icon,
      color: 'statusWarningPrimary',
    };
  }

  return {
    labelId: getLabelIdByTransactionStatus(status),
    icon: getTransactionDetailIconByTransactionStatus(status),
    color: getTransactionDetailIconColorByTransactionStatus(status),
  };
}
