import NOOP from 'constants/noop';

import {
  TMerchantFeatureFlagDispatch,
  TMerchantFeatureFlagState,
} from './MerchantFeatureFlagProvider.types';

export const INITIAL_STATE: TMerchantFeatureFlagState = {
  hasOrderedPhysicalCard: false,
  showDebtBanner: false,
  showCashback: false,
  onlinePaymentEnabled: true,
  addressSubmitted: true,
  businessDetailsSubmitted: true,
  businessOwnersSubmitted: true,
  virtualCardCreationEnabled: false,
  physicalCardCreationEnabled: false,
  xeroEnabled: false,
  teamManagementEnabled: false,
  scheduledTransfersEnabled: false,
  fxEnabled: false,
  bulkTransfersEnabled: false,
  transactionTextSearchEnabled: false,
  accountsReceivableInvoiceEnabled: false,
  christmasBillingCampaignEnabled: false,
  dkkCurrencyAllowed: false,
  sekCurrencyAllowed: false,
  hufCurrencyAllowed: false,
};

export const INITIAL_DISPATCH: TMerchantFeatureFlagDispatch = {
  getMerchantFeatureFlags: NOOP,
  clear: NOOP,
} as TMerchantFeatureFlagDispatch;
