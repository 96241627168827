import gears from 'assets/lotties/gears.json';
import gift from 'assets/lotties/gift.json';
import rocket from 'assets/lotties/rocket.json';
import wamoLogoLoading from 'assets/lotties/wamo-logo-loading.json';
import mailbox from 'assets/lotties/mailbox.json';

const LOTTIES = {
  gears,
  gift,
  rocket,
  wamoLogoLoading,
  mailbox,
};

export default LOTTIES;
