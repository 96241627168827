import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function HufIcon({ width = 24, height = 24 }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <g clipPath="url(#clip0_225_17171)">
        <path
          d="M1.49131 15.6546C0.526628 18.2546 -0.00146484 21.0667 -0.00146484 24.0024C-0.00146484 26.938 0.526628 29.7503 1.49131 32.3501L23.9985 34.4371L46.5057 32.3501C47.4704 29.7503 47.9984 26.938 47.9984 24.0024C47.9984 21.0667 47.4704 18.2546 46.5057 15.6546L23.9985 13.5676L1.49131 15.6546Z"
          fill="#FEFEFE"
        />
        <path
          d="M23.9985 0.00244141C13.6795 0.00244141 4.88239 6.51543 1.49146 15.6546H46.5059C43.1147 6.51543 34.3178 0.00244141 23.9985 0.00244141Z"
          fill="#C8102E"
        />
        <path
          d="M23.9985 48.0023C34.3178 48.0023 43.1147 41.4893 46.5058 32.3501H1.49146C4.88239 41.4893 13.6795 48.0023 23.9985 48.0023Z"
          fill="#008000"
        />
      </g>
      <rect
        x="0.5"
        y="0.499512"
        width="46.9999"
        height="46.9999"
        rx="23.5"
        stroke={colors.borderOutline}
      />
      <defs>
        <clipPath id="clip0_225_17171">
          <rect
            x="-0.00146484"
            y="-0.000244141"
            width="47.9999"
            height="47.9999"
            rx="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HufIcon;
