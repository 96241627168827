import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from 'types/icon';

function AgricultureIcon({
  width = 24,
  height = 24,
  strokeWidth = 2,
  iconColor = 'textPrimary',
}: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M11.9885 22V11.0571"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M14.5 6.66695C14.5 9.24443 12.5952 11.3339 12 11.3339C11.5238 11.3339 9.5 9.24443 9.5 6.66695C9.5 4.08946 11.4643 2 12 2C12.5357 2 14.5 4.08946 14.5 6.66695Z"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17.2496 16.8187C15.1782 18.0364 12.4492 17.1719 12.1211 16.5933C11.8587 16.1304 12.4223 13.176 14.4937 11.9583C16.565 10.7406 19.3268 11.6629 19.6221 12.1836C19.9174 12.7044 19.3209 15.601 17.2496 16.8187Z"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M6.75073 17.3528C4.6794 16.1351 4.05012 13.2964 4.3782 12.7178C4.64066 12.2549 7.43528 11.2747 9.50661 12.4924C11.5779 13.7101 12.1744 16.6067 11.8791 17.1275C11.5839 17.6482 8.82206 18.5705 6.75073 17.3528Z"
        stroke={colors[iconColor]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}

export default AgricultureIcon;
