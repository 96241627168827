import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Text from 'components/core/Text';
import Box from 'components/core/Box';

import { formatAmount } from 'helpers/formatAmount';
import spacing from 'theme/spacing';
import { TI18nId } from 'types/common';
import date from 'helpers/date/date';
import { TLedgerEntrySideLayoutPageProps } from './LedgerEntrySideLayoutPage.types';
import LedgerEntrySideLayoutPageShimmer from './views/LedgerEntrySideLayoutPageShimmer';
import DownloadStatementButton from './views/DownloadStatementButton';

import { StyledSideLayoutPage } from './LedgerEntrySideLayoutPage.styles';
import BeneficiaryAccountInformation from '../v2/BeneficiaryItem/views/BeneficiaryAccountInformation';

function LedgerEntrySideLayoutPage({
  amount,
  currency,
  direction,
  title,
  titleExtra,
  createdAt,
  children,
  statementApiEndpoint,
  statementVisible,
  lineThroughAmount,
  senderInfo,
}: TLedgerEntrySideLayoutPageProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const header = formatAmount({
    amount,
    currency,
    direction,
  });

  function handleCloseSideLayout() {
    const pathname = queryString.exclude(`${location.pathname}${location.search}`, ['txId']);
    navigate(pathname, {
      state: location?.state,
    });
  }

  return (
    <StyledSideLayoutPage
      headerId={header as TI18nId}
      lineThroughAmount={lineThroughAmount}
      onClose={handleCloseSideLayout}>
      <Box paddingX="m" alignment="col.verticalCenter" flex="auto">
        <Box marginBottom="xs" marginTop={`-${spacing.l}`}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap="s"
            mt="s"
            mb="xs">
            {senderInfo ? (
              <Box mb="m">
                <Text
                  id="label.from.withTitle"
                  variant="mediumTextSemiBold"
                  values={{
                    title: senderInfo.name,
                  }}
                />
                <BeneficiaryAccountInformation
                  iban={senderInfo.iban}
                  accountNumber={senderInfo.accountNumber}
                  sortCode={senderInfo.sortCode}
                  dkBankCode={senderInfo.dkBankCode}
                />
              </Box>
            ) : (
              <Text variant="mediumTextSemiBold">{title}</Text>
            )}
            {titleExtra}
          </Box>
          <Text color="textSecondary" variant="smallTextRegular">
            {date.formatWithTimeZone({
              date: createdAt,
              formatType: 'iii, dd MMM, HH:mm',
            })}
          </Text>
        </Box>
        {children}
        <Box
          marginTop="l"
          flex="auto"
          alignment="col.bottom.left"
          justifyContent="flex-end"
          marginBottom="m">
          {statementVisible && (
            <DownloadStatementButton statementApiEndpoint={statementApiEndpoint} />
          )}
        </Box>
      </Box>
    </StyledSideLayoutPage>
  );
}

LedgerEntrySideLayoutPage.Shimmer = LedgerEntrySideLayoutPageShimmer;

export default LedgerEntrySideLayoutPage;
