import React, { createRef, ReactElement } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet';

import AlertModal from 'components/AlertModal';
import IdleModal from 'components/IdleModal';

import { TIdleModalRef } from 'components/IdleModal/IdleModal.types';

import AppRouter from 'router/AppRouter';
import useDisableMultiTab from 'hooks/useDisableMultiTab';

import AllContextProvider from 'context/AllContextProvider';
import { TAlertModalRef } from 'components/AlertModal/AlertModal.types';

import 'configs/i18n';
import 'configs/sentry';

export const alertModalRef = createRef<TAlertModalRef>();
export const idleModalRef = createRef<TIdleModalRef>();

function App(): ReactElement {
  useDisableMultiTab();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>wamo business</title>
        <meta
          name="description"
          content="Login to wamo and manage your business finances effortlessly. Create accounts, make payments, and manage your team, all in one place."
        />
      </Helmet>
      <ErrorBoundary fallback={<div>An error Occurred</div>}>
        <AllContextProvider>
          <AppRouter />
          <AlertModal ref={alertModalRef} />
          <IdleModal ref={idleModalRef} />
        </AllContextProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
