import lazyWithRetry from 'helpers/lazyWithRetry';

import { TAuthRoutes } from './Auth.types';

const Login = lazyWithRetry(() => import('./Login'));
const RegisterEmailCheck = lazyWithRetry(() => import('./RegisterEmailCheck'));
const ChangePassword = lazyWithRetry(() => import('./ChangePassword'));
const SendResetPassword = lazyWithRetry(() => import('./SendResetPassword'));
const ResetPassword = lazyWithRetry(() => import('./ResetPassword'));
const LoginEmailAuthentication = lazyWithRetry(() => import('./LoginEmailAuthentication'));

const OnboardingWaitingList = lazyWithRetry(
  () => import('modals/Onboarding/OnboardingWaitingList')
);

const routes: TAuthRoutes = {
  login: {
    path: '/login',
    element: Login,
    titleId: 'screens.login.title',
  },
  register: {
    path: '/register',
    element: RegisterEmailCheck,
    titleId: 'screens.registerEmailCheck.title',
  },
  authChangePassword: {
    path: '/change-pin',
    element: ChangePassword,
    titleId: 'screens.settings.changePin.title',
  },
  sendResetPassword: {
    path: '/reset-pin',
    element: SendResetPassword,
    titleId: 'screens.headerTitle.sendResetPassword',
  },
  resetPassword: {
    path: '/reset-pin/:token',
    element: ResetPassword,
    titleId: 'screens.headerTitle.resetPassword',
  },
  waitlist: {
    path: '/waitlist',
    element: OnboardingWaitingList,
    titleId: 'screens.headerTitle.home',
  },
  emailAuth: {
    path: '/email-auth',
    element: LoginEmailAuthentication,
    titleId: 'screens.loginEmailAuthentication.title',
  },
};

export default routes;
