import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from 'types/icon';

function Home04Icon({
  width = 24,
  height = 24,
  strokeWidth = 2,
  iconColor = 'textPrimary',
}: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 15.9997C13.6569 15.9997 15 14.6566 15 12.9997C15 11.3429 13.6569 9.99974 12 9.99974C10.3431 9.99974 9 11.3429 9 12.9997C9 14.6566 10.3431 15.9997 12 15.9997Z"
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M3 10.5648C3 9.99045 3 9.70327 3.07403 9.43881C3.1396 9.20454 3.24737 8.9842 3.39203 8.78861C3.55534 8.56781 3.78202 8.3915 4.23539 8.03888L11.0177 2.76375C11.369 2.4905 11.5447 2.35388 11.7387 2.30136C11.9098 2.25502 12.0902 2.25502 12.2613 2.30136C12.4553 2.35388 12.631 2.4905 12.9823 2.76376L19.7646 8.03888C20.218 8.3915 20.4447 8.56781 20.608 8.78861C20.7526 8.9842 20.8604 9.20454 20.926 9.43881C21 9.70327 21 9.99045 21 10.5648V17.7997C21 18.9198 21 19.4799 20.782 19.9077C20.5903 20.284 20.2843 20.59 19.908 20.7818C19.4802 20.9997 18.9201 20.9997 17.8 20.9997H6.2C5.07989 20.9997 4.51984 20.9997 4.09202 20.7818C3.71569 20.59 3.40973 20.284 3.21799 19.9077C3 19.4799 3 18.9198 3 17.7997V10.5648Z"
        stroke={colors[iconColor]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}

export default Home04Icon;
