import { FC } from 'react';

import {
  BankIcon,
  AudIcon,
  CadIcon,
  ChfIcon,
  EurIcon,
  GbpIcon,
  JpyIcon,
  NokIcon,
  NzdIcon,
  SekIcon,
  UsdIcon,
  DkkIcon,
  HufIcon,
} from 'components/Icons';

import { TCurrency } from 'types/common';

enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  NOK = 'NOK',
  NZD = 'NZD',
  SEK = 'SEK',
  USD = 'USD',
  HUF = 'HUF',
  DKK = 'DKK',
}

const MAP: Record<
  TCurrency['code'],
  { name: string; code: Currency; symbol: string; icon: FC<{ width?: number; height?: number }> }
> = {
  [Currency.AUD]: {
    name: 'Australian Dollar',
    code: Currency.AUD,
    symbol: '$',
    icon: AudIcon,
  },
  [Currency.CAD]: {
    name: 'Canadian Dollar',
    code: Currency.CAD,
    symbol: '$',
    icon: CadIcon,
  },
  [Currency.CHF]: { name: 'Swiss Franc', code: Currency.CHF, symbol: '₣', icon: ChfIcon },
  [Currency.EUR]: { name: 'Euro', code: Currency.EUR, symbol: '€', icon: EurIcon },
  [Currency.GBP]: {
    name: 'British pound',
    code: Currency.GBP,
    symbol: '£',
    icon: GbpIcon,
  },
  [Currency.JPY]: { name: 'Yen', code: Currency.JPY, symbol: '¥', icon: JpyIcon },
  [Currency.NOK]: {
    name: 'Norwegian Krone',
    code: Currency.NOK,
    symbol: 'kr',
    icon: NokIcon,
  },
  [Currency.NZD]: {
    name: 'New Zealand Dollar',
    code: Currency.NZD,
    symbol: '$',
    icon: NzdIcon,
  },
  [Currency.SEK]: {
    name: 'Swedish Krona',
    code: Currency.SEK,
    symbol: 'kr',
    icon: SekIcon,
  },
  [Currency.USD]: { name: 'US Dollar', code: Currency.USD, symbol: '$', icon: UsdIcon },
  [Currency.HUF]: {
    name: 'Hungarian forints',
    code: Currency.HUF,
    symbol: 'Ft',
    icon: HufIcon,
  },
  [Currency.DKK]: {
    name: 'Danish Krone',
    code: Currency.DKK,
    symbol: 'kr.',
    icon: DkkIcon,
  },
};

export function getIconByCurrency(
  currency: TCurrency['code']
): FC<{ width?: number; height?: number }> {
  return MAP[currency]?.icon ?? BankIcon;
}

export function getNameByCurrency(currency: TCurrency['code']) {
  return MAP[currency]?.name;
}

export function getCodeByCurrency(currency: TCurrency['code']) {
  return MAP[currency]?.code;
}

export function getSymbolByCurrency(currency: TCurrency['symbol']) {
  return MAP[currency]?.symbol;
}

export function getCurrencyByCurrencyCode(currencyCode: TCurrency['code']): TCurrency {
  const currency = MAP[currencyCode];
  return {
    name: currency.name,
    code: currency.code,
    symbol: currency.symbol,
    minorUnit: 2,
  };
}

export default Currency;
