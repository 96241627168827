import React from 'react';
import { useTheme } from 'styled-components';

import { TIconProps } from '../Icon.types';

function DkkIcon({ width = 24, height = 24 }: TIconProps) {
  const { colors } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <g clipPath="url(#clip0_225_17218)">
        <path
          d="M23.999 48.0028C37.2538 48.0028 47.9989 37.2577 47.9989 24.0029C47.9989 10.7481 37.2538 0.00292969 23.999 0.00292969C10.7442 0.00292969 -0.000976562 10.7481 -0.000976562 24.0029C-0.000976562 37.2577 10.7442 48.0028 23.999 48.0028Z"
          fill="#FEFEFE"
        />
        <path
          d="M18.7818 20.8717H47.7959C46.2619 9.09612 36.1929 0.00219727 23.9991 0.00219727C22.2071 0.00219727 20.4617 0.200291 18.7817 0.572665V20.8717H18.7818Z"
          fill="#C8102E"
        />
        <path
          d="M12.5207 20.8717V2.92041C5.93073 6.51609 1.21305 13.1115 0.202148 20.8718H12.5207V20.8717Z"
          fill="#C8102E"
        />
        <path
          d="M12.5206 27.1326H0.202148C1.21305 34.8929 5.93073 41.4883 12.5207 45.0839L12.5206 27.1326Z"
          fill="#C8102E"
        />
        <path
          d="M18.7817 27.1327V47.4317C20.4617 47.804 22.2071 48.0021 23.9991 48.0021C36.1929 48.0021 46.2619 38.9082 47.7959 27.1326H18.7817V27.1327Z"
          fill="#C8102E"
        />
      </g>
      <rect
        x="0.5"
        y="0.499512"
        width="46.9999"
        height="46.9999"
        rx="23.5"
        stroke={colors.borderOutline}
      />
      <defs>
        <clipPath id="clip0_225_17218">
          <rect
            x="-0.000976562"
            y="-0.000244141"
            width="47.9999"
            height="47.9999"
            rx="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DkkIcon;
