import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/core/Box';

import { StyledPaymentDetailsTable } from '../InvoicePreviewPdf.styles';
import { TInvoicePreviewPdfPaymentDetailsProps } from '../InvoicePreviewPdf.types';

function InvoicePreviewPdfPaymentDetails({
  registrationName,
  ledger,
  paymentReferenceNumber,
  footer,
}: TInvoicePreviewPdfPaymentDetailsProps) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      p="m"
      backgroundColor="backgroundPrimary"
      borderRadius={12}>
      <StyledPaymentDetailsTable>
        <tbody>
          <tr>
            <th style={{ width: '65%' }}>{t('label.paymentDetails')}</th>
            <th style={{ paddingLeft: '36px' }}>{t('label.note')}</th>
          </tr>
          <tr>
            <td className="payment-details">
              <table>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '8px' }}>{t('label.beneficiaryName')}</td>
                    <td style={{ paddingTop: '8px' }}>{registrationName}</td>
                  </tr>
                  {ledger.bicSwiftCode && (
                    <tr>
                      <td>{t('label.bic')}</td>
                      <td>{ledger.bicSwiftCode}</td>
                    </tr>
                  )}
                  {ledger.iban && (
                    <tr>
                      <td>{t('label.iban')}</td>
                      <td>{ledger.iban}</td>
                    </tr>
                  )}
                  {ledger.accountNumber && (
                    <tr>
                      <td>{t('label.accountNumber')}</td>
                      <td>{ledger.accountNumber}</td>
                    </tr>
                  )}
                  {ledger.sortCode && (
                    <tr>
                      <td>{t('label.sortCode')}</td>
                      <td>{ledger.sortCode}</td>
                    </tr>
                  )}
                  {ledger.dkBankCode && (
                    <tr>
                      <td>{t('label.bankCode')}</td>
                      <td>{ledger.dkBankCode}</td>
                    </tr>
                  )}
                  <tr>
                    <td>{t('label.reference')}</td>
                    <td>{paymentReferenceNumber}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ paddingLeft: '36px' }}>{footer}</td>
          </tr>
        </tbody>
      </StyledPaymentDetailsTable>
    </Box>
  );
}

export default InvoicePreviewPdfPaymentDetails;
