import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';
import API_ENDPOINT from 'constants/apiEndpoint';
import { TLedger } from 'types/ledger';
import LedgerStatus from 'enums/LedgerStatus';
import normalizeUrl from 'helpers/normalizeUrl';

import { INITIAL_DISPATCH, INITIAL_STATE } from './LedgersProvider.constants';
import { TLedgersContext, TLedgersState } from './LedgersProvider.types';

const ledgersContext = createContext<TLedgersContext>({
  ledgersState: INITIAL_STATE,
  ledgersDispatch: INITIAL_DISPATCH,
});

function LedgersProvider({ children }: TChildrenOnly): ReactElement {
  const {
    get: requestGetLedgers,
    data: ledgersData,
    loading,
  } = useFetch<TCommonResponse<TLedger[]>>(
    normalizeUrl(API_ENDPOINT.LEDGERS, { queryParams: { status: LedgerStatus.ACTIVE } })
  );
  const [{ ledgers, selectedLedger }, setState] =
    useState<Omit<TLedgersState, 'loading'>>(INITIAL_STATE);

  useEffect(() => {
    if (ledgersData?.result) {
      setLedgers({
        ledgers: ledgersData?.result ?? [],
      });
    }
  }, [ledgersData]);

  const setLedgers = useCallback((data = {}) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  }, []);

  const setSelectedLedger = useCallback(
    (selectedLedgerValue) =>
      setState((state) => ({
        ...state,
        selectedLedger: selectedLedgerValue,
      })),
    []
  );

  function findMainLedger() {
    return ledgers.find((ledger) => ledger.isMainLedger);
  }

  function getInitialSelectedLedger() {
    return (selectedLedger || findMainLedger()) as TLedger;
  }

  function getInitialTopUpLedger(ledger?: TLedger): TLedger {
    if (ledger?.canBeToppedUp) {
      return ledger;
    }
    if (selectedLedger?.canBeToppedUp) {
      return selectedLedger;
    }
    return findMainLedger() as TLedger;
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value = useMemo(
    (): TLedgersContext => ({
      ledgersState: { ledgers, selectedLedger, loading },
      ledgersDispatch: {
        setLedgers,
        setSelectedLedger,
        findMainLedger,
        requestGetLedgers,
        getInitialSelectedLedger,
        getInitialTopUpLedger,
        clear,
      },
    }),
    [
      ledgers,
      selectedLedger,
      setLedgers,
      setSelectedLedger,
      findMainLedger,
      requestGetLedgers,
      loading,
      clear,
    ]
  );

  return <ledgersContext.Provider value={value}>{children}</ledgersContext.Provider>;
}

export default LedgersProvider;

export const useLedgersContext = (): TLedgersContext => useContext(ledgersContext);
